import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Input, InputNumber, Select, Card, notification, Tooltip, Modal } from "antd";
import { SearchOutlined, BarcodeOutlined, PlusOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { ModalManutencaoNota, ModalSelectLote, ModalSenhaLiberacao } from "../modals";
import { HasValue, isObjetoDiffVazio } from "../../services/funcoes";
import { InputPreco, SelectProduto, SelectPaginacao } from "../../components";
import { selectPaginadoActions } from "../../actions";
import { CalcularImpostoItem } from "../../services/calculaImposto";

export default function FiltroProdutoNfi({ form, dadosTabela, dadosEndereco = {}, dadosOperacao = {}, dadosVendedor = {}, btnEditando, itemEditando, carregarPagina }) {

    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [dadosDetalhes, setDadosDetalhes] = useState({});
    const [desabilitarProd_id, setDesabilitarProd_id] = useState(false);
    const [buscarProdutosCodBarra, setBuscarProdutosCodBarra] = useState(null);
    const [dadosCfop, setDadosCfop] = useState([]);
    const [abrirModalManutencaoNota, setAbrirModalManutencaoNota] = useState(false);
    const [dadosProdutoImpostos, setDadosProdutoImpostos] = useState({});
    const [{ manutencao }, dispatch] = useStateValue();
    const [dadosContribuinte, setDadosContribuinte] = useState({});
    const [dadosNcm, setDadosNcm] = useState([]);
    const [listaPreco, setListaPreco] = useState([]);
    const [ocultaListaPreco, setOcultaListaPreco] = useState(false);
    const [listaSelectProd, setListaSelectProd] = useState([]);
    const codBarras = useRef();
    const [abreModalLiberacao, setAbreModalLiberacao] = useState(false);
    const [liberacaoSenha, setLiberacaoSenha] = useState(null);
    const [controleLote, setControleLote] = useState(false);
    const [abrirModalLote, setAbrirModalLote] = useState(false);
    const [dadosLote, setDadosLote] = useState([]);
    const [dadosItem, setDadosItem] = useState([]);
    const [nfSaida, setNfSaida] = useState(false);
    const [numeroPedido, setNumeroPedido] = useState(null);
    const [retImposto, setRetImposto] = useState({});

    useEffect(() => {
        if (btnEditando.editando && isObjetoDiffVazio(itemEditando)) {
            editandoItem(itemEditando);
            setExibirDetalhes(true);
        }
    }, [btnEditando.editando])

    useEffect(() => {
        popularDadosContribuintes();
        carregaDados();
    }, []);

    useEffect(() => {
        if (dadosOperacao.tipooperacao !== undefined) {
            if (dadosOperacao.tipooperacao.tip_entradasaida === 1) {
                setNfSaida(true);
            } else if (dadosOperacao.tipooperacao.tip_entradasaida === 0) {
                setNfSaida(false);
            }
        }
    }, [dadosOperacao])

    useEffect(() => {
        if (buscarProdutosCodBarra && !!codBarras && !!codBarras.current) {
            codBarras.current.focus();
        }
    }, [buscarProdutosCodBarra]);

    useEffect(() => {
        if (isObjetoDiffVazio(dadosContribuinte)) {
            if (!!manutencao.dados && (!!form.getFieldsValue().vnc_id || !!form.getFieldsValue().ped_id)) {
                incluirItemPedidoCondicional();
            }
        }
    }, [dadosContribuinte]);

    //#region dados impostos produtos 

    async function buscarDadosProdImpostos(produto, listapreco) {
        debugger;
        let destinoOperacao = (!!dadosEndereco.est_sigla && !!dadosContribuinte.est_sigla && (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) ? '1' : '2');
        let codigoProd = !!produto ? produto : form.getFieldValue().pro_id;
        let ufDest = !!dadosEndereco.est_sigla ? dadosEndereco.est_sigla : dadosContribuinte.est_sigla;
        let indicadorPresenca = !!form.getFieldsValue().ntf_indicadorpresenca ? `&indicadorPresenca=${form.getFieldsValue().ntf_indicadorpresenca}` : '';
        let listaPreco = !!listapreco ? `&ListaPreco=${listapreco}` : '';
        let inscEstadual = !!form.getFieldValue().pej_inscricaoestadual ? `&inscEstadual=${form.getFieldValue().pej_inscricaoestadual}` : '';
        let listaPrecoPar = !!listapreco ? `&ListaPreco=${listapreco}` : '';
        let objProd = (await api.get(`Produto/BuscarDadosProduto?IdProduto=${codigoProd}&ufDestino=${ufDest}`
            + `&operacaoCodigo=${dadosOperacao.ope_id}&ufOrigem=${dadosContribuinte.est_sigla}&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}`
            + `&empresaEnquadramento=${dadosContribuinte.emp_regimetributario}&destinoOperacao=${destinoOperacao}${indicadorPresenca}${inscEstadual}${listaPrecoPar}`
        )).data[0];
        setDadosProdutoImpostos(objProd);
        form.setFieldsValue({
            pis_id: objProd.pis_id,
            cbf_id: !!dadosOperacao.cbf_id ? dadosOperacao.cbf_id : objProd.cbf_id, // se tem fixo na operação 
            ncm_id: objProd.ncm_id,
            icm_id: !!form.getFieldValue().icm_id ? form.getFieldValue().icm_id : objProd.icm_id,
            nfi_cfop: objProd.cfo_cfop,
            informacaoAdicional: !!form.getFieldValue().informacaoAdicional ? form.getFieldValue().informacaoAdicional : objProd.pro_observacao
        });
        return objProd;
    };

    useEffect(() => {
        if (!!dadosProdutoImpostos && JSON.stringify(dadosProdutoImpostos) !== '{}') {
            abrirModalManutencaoProd();
        }
    }, [dadosProdutoImpostos]);


    function abrirModalManutencaoProd() {
        if (dadosContribuinte.emp_regimetributario === 3 && (!!!dadosProdutoImpostos.icm_id || !!!dadosProdutoImpostos.ncm_id || !!!dadosProdutoImpostos.ump_id ||
            !!!dadosProdutoImpostos.pro_descricao || !!!dadosProdutoImpostos.pis_id || !!!dadosProdutoImpostos.iod_ufdestino)) {
            setAbrirModalManutencaoNota(true);
        } else if (dadosContribuinte.emp_regimetributario !== 3 && (!!!dadosProdutoImpostos.icm_id || !!!dadosProdutoImpostos.ncm_id || !!!dadosProdutoImpostos.ump_id)) {
            setAbrirModalManutencaoNota(true);
        } else if (!!dadosProdutoImpostos.cst_id && !!!dadosProdutoImpostos.mva_ufdestino && (dadosProdutoImpostos.cst_id === '10' || dadosProdutoImpostos.cst_id === '30' || dadosProdutoImpostos.cst_id === '70')) {
            setAbrirModalManutencaoNota(true);
        }
    };

    async function carregarNcm(id) {
        api.get(`Ncm/Buscar/${id}`).then(res => {
            setDadosNcm(res.data);
            dadosProdutoImpostos.ncm_ativo = res.data.ncm_ativo;
        }).catch(
            error => {
                console.log(error);
            }
        )
    }

    function carregarSelectPaginados() {
        let lista = [];
        if (!!form.getFieldValue().ltp_id)
            lista.push({ name: "ltp_id", campo: "idLista", value: form.getFieldValue().ltp_id });

        if (!!form.getFieldsValue().icm_id)
            lista.push({ name: "icm_id", campo: "Codigo", value: form.getFieldsValue().icm_id })

        if (!!form.getFieldsValue().pis_id)
            lista.push({ name: "pis_id", campo: "Id", value: form.getFieldsValue().pis_id })

        if (!!form.getFieldValue().ncm_id)
            lista.push({ name: "ncm_id", campo: "IdNcm", value: form.getFieldValue().ncm_id });

        if (dadosContribuinte.emp_regimetributario === 3 && (!!form.getFieldValue().cbf_id || !!dadosOperacao.cbf_id))
            lista.push({ name: "cbf_id", campo: "Id", value: !!dadosOperacao.cbf_id ? dadosOperacao.cbf_id : form.getFieldValue().cbf_id });

        if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
    }

    //#endregion

    //#region Buscar dados banco 

    function carregaDados() {
        buscarDadosCfop();
    };

    async function popularDadosContribuintes(callback) {
        await api.get(`dadosContribuinte/Buscar`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosContribuinte(res.data);
                    if (callback) {
                        callback();
                    }
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function buscarDadosCfop() {
        api.get(`Cfop/ListarCfopTipo?tipoCfopFim=8000&tipoCfopIni=1000`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosCfop(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    //#endregion

    //#region Funções de campos do formulario 

    async function onChangeProID(pro_id) {
        let listaItens = [...listaSelectProd]
        let produto = listaItens.filter(pro => pro.key === pro_id)[0];
        let filtros = !!produto.pro_id ? `idProduto=${produto.pro_id}` : '';
        filtros += !!produto.prg_id ? `&idProdutoGrade=${produto.prg_id}` : '';
        filtros += !!dadosOperacao.ope_id ? `&idOperacaoFiscal=${dadosOperacao.ope_id}` : '';
        filtros += !!form.getFieldValue().cli_id ? `&idCliente=${form.getFieldValue().cli_id}` : '';
        let listaPrecoTemp = (await api.get(`ListaPreco/ListaPrecoPadraoProduto?${filtros}`)).data;
        let listaPrecoCliente = (await api.get(`ListaPrecoCliente/ListarListaPrecoCliente?${filtros}`)).data;
        let listapreco = (!!!listaPrecoCliente || listaPrecoCliente.length > 0) ? listaPrecoCliente[0].ltp_id : listaPrecoTemp[0].ltp_id;
        setControleLote(produto.pro_controlelote)
        if (!!listaPrecoCliente && listaPrecoCliente.length > 0) {
            form.setFieldsValue({ ltp_id: listaPrecoCliente[0].ltp_id });
            produto.lpi_valorvenda = listaPrecoCliente[0].ltp_id;
            if (dadosOperacao?.ope_tipoprecoutilizar === 2 || dadosOperacao?.ope_tipoprecoutilizar === 3) {
                setOcultaListaPreco(true);
            } else {
                setOcultaListaPreco(false);
            }
            setListaPreco(listaPrecoCliente);
        } else {
            form.setFieldsValue({ ltp_id: listaPrecoTemp[0].ltp_id });
            produto.lpi_valorvenda = listaPrecoTemp[0].lpi_valorvenda;
            if (dadosOperacao?.ope_tipoprecoutilizar === 2 || dadosOperacao?.ope_tipoprecoutilizar === 3) {
                setOcultaListaPreco(true);
            } else {
                setOcultaListaPreco(false);
            }
            setListaPreco(listaPrecoTemp);
        }
        form.setFieldsValue({ nfi_numeropedidocompra: numeroPedido });

        let produtoImposto = await buscarDadosProdImpostos(produto.pro_id, listapreco);
        produtoImposto.prg_id = produto.prg_id;
        produtoImposto.prg_ean = produto.prg_ean;
        produtoImposto.descricaograde = produto.descricaograde;
        await popularFormProduto(produtoImposto);
        carregarSelectPaginados();
    };

    async function onPressEnterProCodigo(campo) {
        let codigoEan = campo.target.value.trim();
        let dadosRequisicao = await api.get(`Produto/ListaProdutoFiltro?filtro=${codigoEan}`);
        if (!!dadosRequisicao.data && dadosRequisicao.data.items.length > 0) {
            let dadosProduto = dadosRequisicao.data.items[0];
            let produtoImposto = await buscarDadosProdImpostos(dadosProduto.pro_id, form.getFieldValue().ltp_id);
            produtoImposto.prg_id = dadosProduto.prg_id;
            produtoImposto.descricaograde = dadosProduto.descricaograde;
            await popularFormProduto(produtoImposto);
            adicionarItem(produtoImposto);
            if (!!codBarras && !!codBarras.current) {
                codBarras.current.focus();
            }
        }
    };

    const togglePesquisa = () => {
        setBuscarProdutosCodBarra(!buscarProdutosCodBarra);
    };

    function onChangeQuantidade() {
        recalcularValorTotal();
    };

    function onChangeValorUnitario() {
        recalcularValorTotal();
    };

    function onChangePercDesc() {
        calculaDesconto('PERC');
    };

    function onChangeDesconto() {
        calculaDesconto('VALOR');
    };

    function onChangePercAcresc() {
        calculaAcrescimo('PERC');
    };

    function onChangeAcrescimo() {
        calculaAcrescimo('VALOR');
    };

    function onClickBtnCancelar() {
        cancelar();
    };

    function onClickBtnAdicionar() {
        adicionarItem();
    }

    //#endregion

    //#region calculos do item 

    function recalcularValorTotal() {
        let total = 0;
        total = parseFloat(parseFloat(form.getFieldValue().quantidade) * parseFloat(form.getFieldValue().valorUnitario));
        form.setFieldsValue({ valorTotalItem: total.toFixed(2) });
        calculaDesconto('ATT');
        calculaAcrescimo('ATT');
    };

    function calculaDesconto(campo) {
        let valor = parseFloat((!!form.getFieldValue().desconto ? form.getFieldValue().desconto : 0));
        let perc = parseFloat((!!form.getFieldValue().percdesc ? form.getFieldValue().percdesc : 0));
        let tot = parseFloat(form.getFieldValue().valorTotalItem);
        let result = 0;
        if (validarDescontoMaximoVendedor(perc)) {
            if (!!campo) {
                if (campo === 'PERC') {
                    result = (tot * perc) / 100;
                    form.setFieldsValue({ desconto: result.toFixed(2) });
                } else if (campo === 'ATT') {
                    result = (tot * perc) / 100;
                    form.setFieldsValue({ desconto: result.toFixed(2) });
                    valor = (!!form.getFieldValue().desconto ? parseFloat(form.getFieldValue().desconto) : 0);
                    result = (!!tot > 0 ? (valor * 100) / tot : 0);
                    form.setFieldsValue({ percdesc: result.toFixed(2) });
                } else {
                    if (valor > tot) {
                        notification.warning({ message: 'Aviso!', description: 'Valor de desconto maior do que valor total do item!' });
                        form.setFieldsValue({ desconto: tot });
                    }
                    result = (valor * 100) / tot;
                    form.setFieldsValue({ percdesc: result.toFixed(2) });
                }
                calculaTotalItemDescAcr();
            }
        } else {
            form.setFieldsValue({ desconto: 0 });
            form.setFieldsValue({ percdesc: 0 });
            calculaTotalItemDescAcr();
        }
    };

    function calculaAcrescimo(campo) {
        let valor = (!!form.getFieldValue().acrescimo ? form.getFieldValue().acrescimo : 0);
        let perc = (!!form.getFieldValue().percacresc ? form.getFieldValue().percacresc : 0);
        let tot = (!!form.getFieldValue().valorTotalItem ? parseFloat(form.getFieldValue().valorTotalItem) : 0);
        let result = 0;

        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ acrescimo: result.toFixed(2) });
            } else if (campo === 'ATT') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ acrescimo: result.toFixed(2) });
                valor = !!form.getFieldValue().acrescimo ? parseFloat(form.getFieldValue().acrescimo) : 0;
                result = (tot > 0 ? (valor * 100) / tot : 0);
                form.setFieldsValue({ percacresc: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ percacresc: result.toFixed(2) });
            }
            calculaTotalItemDescAcr();
        }
    };

    function calculaTotalItemDescAcr() {
        let valorTot = 0;
        let valorItens = !!form.getFieldValue().valorTotalItem ? form.getFieldValue().valorTotalItem : 0;
        let valorAcrescimo = !!form.getFieldValue().acrescimo ? form.getFieldValue().acrescimo : 0;
        let valorDesc = !!form.getFieldValue().desconto ? form.getFieldValue().desconto : 0;

        valorTot = parseFloat(valorItens) + parseFloat(valorAcrescimo) - parseFloat(valorDesc);
        form.setFieldsValue({ valorItemDesconto: valorTot.toFixed(2) });
    };

    //#endregion

    //#region processos itens 

    function cancelar() {
        setExibirDetalhes(false);
        setDadosDetalhes({});
        setDesabilitarProd_id(false);
        limparCampos();
        if (btnEditando.editando) {
            btnEditando.setEditando(false);
        }
    };

    async function popularFormProduto(produto, dadosImposto, callback) {
        let valorVenda = (!!produto.lpi_valorvenda ? produto.lpi_valorvenda : 0);
        setExibirDetalhes(true);
        form.setFieldsValue({
            quantidade: 1,
            valorUnitario: valorVenda,
            valorTotalItem: valorVenda,
            valorItemDesconto: valorVenda,
            nfi_itempedidocompra: null
        });
        setDadosDetalhes(produto);
        return produto;
    };

    async function carregarParametrosLocal(item, listaItens, lista) {
        const codigo = item.pro_id
        const codigoGrade = item.prg_id
        const quantidade = item.quantidade

        let resParams = await api.get(`ParametroLocal/BuscarParametros`);
        let paramsEstoque = resParams.data.par_avisoestoqueinsuficiente;

        let dados = lista.filter(item => item.key === form.getFieldValue().pro_id || item.pro_id === form.getFieldValue().pro_id)[0];
        var dadosIncluido = listaItens
            .filter((prod) => (prod.pro_id === dados.pro_id && (!!!dados.prg_id || dados.prg_id === prod.prg_id)))
            .reduce((total, prod) => total + prod.nfi_qtde, 0);

        if (paramsEstoque === null) {
            return;
        }

        let resEstoque = await api.get(`Estoque/BuscarEstoqueProduto?codigoProduto=${codigo}` + (codigoGrade ? `&codigoGrade=${codigoGrade}` : ''))
        let estoque = resEstoque.data.valorTotal;

        let valorEstoque = calcularValorEstoque(estoque, dadosIncluido, quantidade);

        return new Promise((resolve, reject) => {
            if (valorEstoque <= 0) {
                estoqueInsuficiente(paramsEstoque, valorEstoque, resolve, reject);
            } else {
                resolve();
            }
        });
    }

    function calcularValorEstoque(estoque, dadosIncluido, quantidade) {
        if (dadosIncluido !== 0) {
            var novosDadosIncluido = dadosIncluido + quantidade
        }
        if (novosDadosIncluido > estoque) {
            return estoque - novosDadosIncluido;
        } else if (quantidade > estoque) {
            return estoque - quantidade;
        } else {
            return estoque
        }
    }

    function estoqueInsuficiente(paramsEstoque, valorEstoque, resolve, reject) {
        if (paramsEstoque === 0) {
            Modal.confirm({
                title: 'Aviso!',
                icon: <ExclamationCircleOutlined />,
                content: `Este produto está sem estoque disponível (Estoque: ${valorEstoque}). Deseja continuar?`,
                okText: 'Continuar',
                cancelText: 'Cancelar',
                centered: true,
                onOk() {
                    resolve();
                },
                onCancel() {
                    reject();
                }
            });
        } else if (paramsEstoque === 1) {
            Modal.confirm({
                title: 'Aviso!',
                icon: <ExclamationCircleOutlined />,
                content: `Este produto está sem estoque disponível (Estoque: ${valorEstoque}). Deseja continuar? Será solicitado uma senha Gerencial`,
                okText: 'Continuar',
                cancelText: 'Cancelar',
                centered: true,
                onOk() {
                    setAbreModalLiberacao(true);
                    setLiberacaoSenha({ resolve, reject });
                },
                onCancel() {
                    reject();
                }
            });
        } else if (paramsEstoque === 2) {
            Modal.warning({
                title: 'Atenção Sistema de Controle de Estoque!',
                icon: <ExclamationCircleOutlined />,
                content: `Produto com estoque ${valorEstoque}, sistema está configurado para bloquear vendas de produtos sem estoque!`,
                centered: true,
                onOk() {
                    reject();
                }
            });
        }
    }

    const onCloseModalLiberacao = (aprovado) => {
        setAbreModalLiberacao(false);
        if (aprovado && liberacaoSenha) {
            liberacaoSenha.resolve();
        } else if (liberacaoSenha) {
            liberacaoSenha.reject();
        }
        setLiberacaoSenha(null);
    }

    async function adicionarItem(item = {}) {
        debugger;
        let possuiIe = !!form.getFieldValue().pej_inscricaoestadual ? true : false;
        let fisicaJuridica = form.getFieldValue().ntf_cpfcnpj.length === 14 ? 2 : form.getFieldValue().ntf_cpfcnpj.length == 11 ? 1 : 3;
        let p = dadosProdutoImpostos;
        p.icms_id = !!form.getFieldValue().icm_id ? form.getFieldValue().icm_id : dadosProdutoImpostos.icm_id;
        if (p.ncm_ativo == false) {
            notification.warning({ message: 'Aviso!', description: 'NCM do produto está invativo na base de dados do SEFAZ, altere o NCM no cadastro do produto para seguir com operação!' });
            return;
        }
        if (!!!p.iod_ufdestino) {
            notification.warning({ message: 'Aviso!', description: 'Não encontrado Dados de Origem e Destino no cadastro de tributação para o UF destino, Verifique no cadastro de tributação do produto!' });
            return
        }
        let impostos = await CalcularImpostoItem(p.orm_id, p.cst_id, p.cso_id, p.iod_uforigem, p.iod_ufdestino, form.getFieldValue().quantidade, form.getFieldValue().valorUnitario,
            form.getFieldValue().nfi_valorfrete, form.getFieldValue().acrescimo, form.getFieldValue().desconto, p.iod_percicms, p.iod_percdiferimentoicms, p.iod_percicmsreducaobc,
            p.mva_percaliquotaicms, p.mva_percmva, p.mva_percaliquotaicmsreducao, p.mva_ajustado, p.ncm_percentual, fisicaJuridica, form.getFieldValue().ntf_consumidorfinal, possuiIe,
            form.getFieldValue().ntf_indicadorpresenca);

        let listaItens = [...dadosTabela.dadosItens];
        let lista = [...listaSelectProd]
        let dadosFormulario = form.getFieldValue();
        setNumeroPedido(dadosFormulario.nfi_numeropedidocompra);
        if (!buscarProdutosCodBarra) {
            if (btnEditando.editando) {
                item = listaItens.filter((prod) => (prod.nfi_numeroitem === dadosFormulario.nfi_numeroitem))[0];
            } else {
                item = { ...item, ...dadosFormulario, ...dadosDetalhes };
            }
        }
        if (
            dadosOperacao.tipooperacao.tip_entradasaida === 1 &&
            !btnEditando.editando) {
            try {
                await carregarParametrosLocal(item, listaItens, lista);
            } catch (error) {
                return;
            }
        }
        let dados = {};
        if (!!validarItem(dadosFormulario)) {
            let dadosEditando = listaItens.filter((prod) => (prod.pro_id === item.pro_id && (!!!item.prg_id || item.prg_id === prod.prg_id)))[0];
            if (!!dadosEditando) {
                dadosEditando.impostos = impostos;
                dados = popularItemNota(true, item, dadosEditando);
                if (!!btnEditando && btnEditando.editando) {
                    btnEditando.setEditando(false);
                    alterarItem(dados, listaItens, dadosEditando)
                } else {
                    if (buscarProdutosCodBarra) {
                        dados.nfi_numeroitem = listaItens.length + 1;
                        incluirItem(dados, listaItens);
                    } else {
                        Modal.confirm({
                            title: 'Atualizar?',
                            icon: <ExclamationCircleOutlined />,
                            content: 'Item já cadastrado na nota, deseja atualiazar suas informações?',
                            okText: 'Atualizar',
                            cancelText: 'Incluir',
                            centered: true,
                            onOk() {
                                alterarItem(dados, listaItens, dadosEditando)
                            },
                            onCancel() {
                                dados.nfi_numeroitem = listaItens.length + 1;
                                if (!!controleLote) {
                                    verificarItemLote(dados, listaItens)
                                } else {
                                    incluirItem(dados, listaItens)
                                }
                            }
                        });
                    }
                }
            } else {
                item.impostos = impostos;
                dados = popularItemNota(false, item);
                dados.nfi_numeroitem = listaItens.length + 1;
                if (!!controleLote) {
                    verificarItemLote(dados, listaItens)
                } else {
                    incluirItem(dados, listaItens)
                }
            }
            setExibirDetalhes(false);
            setDesabilitarProd_id(false);
            limparCampos();
        }
    };
    
    function buscarDadosIcms(value) {
        let pro_id = form.getFieldValue().pro_id
        let listaItens = [...listaSelectProd]
        let produto = listaItens.filter(pro => pro.key === pro_id)[0];

        let codigoProd = btnEditando.editando ? form.getFieldValue().pro_id : produto.pro_id
        let ufDest = !!dadosEndereco.est_sigla ? dadosEndereco.est_sigla : dadosContribuinte.est_sigla;
        let destinoOperacao = (!!dadosEndereco.est_sigla && !!dadosContribuinte.est_sigla && (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) ? '1' : '2');
        let indicadorPresenca = !!form.getFieldsValue().ntf_indicadorpresenca ? `&indicadorPresenca=${form.getFieldsValue().ntf_indicadorpresenca}` : '';
        let inscEstadual = !!form.getFieldValue().pej_inscricaoestadual ? `&inscEstadual=${form.getFieldValue().pej_inscricaoestadual}` : '';
        let listaPrecoPar = !!listaPreco ? `&ListaPreco=${listaPreco[0].ltp_id}` : '';

        api.get(`Produto/BuscarDadosProduto?IdProduto=${codigoProd}&ufDestino=${ufDest}&IcmsId=${value}`
            + `&operacaoCodigo=${dadosOperacao.ope_id}&ufOrigem=${dadosContribuinte.est_sigla}&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}`
            + `&empresaEnquadramento=${dadosContribuinte.emp_regimetributario}&destinoOperacao=${destinoOperacao}${indicadorPresenca}${inscEstadual}${listaPrecoPar}`
        ).then(
            res => {
                setRetImposto(res.data[0]);
            }
        )
    }

    function validarItem(dados) {
        if (dados.quantidade === 0 || !!!dados.quantidade) {
            notification.warning({ message: 'Aviso!', description: 'Quantidade do Item Inválida!' });
            return false;
        }
        if (parseFloat(dados.valorItemDesconto) === 0 || parseFloat(dados.valorUnitario) === 0) {
            notification.warning({ message: 'Aviso!', description: 'Valor do Item Inválido!' });
            return false
        }
        if (!!!dados.nfi_cfop) {
            notification.warning({ message: 'Aviso', description: 'Campo CFOP é obrigatório!' });
            return false;
        }
        return true;
    }

    async function verificarItemLote(dados, listaItens) {
        let listaLote = (await api.get(`ProdutoLote/ListarLotesSaldo?IdProduto=${dados.pro_id}&ComSaldo=true`));
        if (listaLote.data.length > 0) {
            let seq = 0;
            listaLote.data.forEach((lote) => {
                lote.key = seq;
                seq++;
            });
            setDadosLote(listaLote.data);
            setDadosItem(dados);
            setAbrirModalLote(true);
        } else {
            //incluirItem(dados, listaItens)
            notification.warning({ message: 'Aviso', description: 'Nenhum lote encontrado para o produto selecionado!' });
            return false;
        }
    }

    function salvarLotes(itemCLote, listaTemp) {
        setAbrirModalLote(false);
        if (itemCLote !== undefined && listaTemp !== undefined) {
            let dados = itemCLote;
            let listaItens = [...dadosTabela.dadosItens];
            if (listaTemp.length > 0) {
                dados.nfitemlotes = listaTemp;
                incluirItem(dados, listaItens)
            }
        }
    }

    function incluirItem(dados, listaItens) {
        listaItens.push(dados);
        dadosTabela.setDadosItens(listaItens);
        notification.success({ message: 'Aviso', description: 'Item incluído com sucesso!' });
    }

    function alterarItem(dados, listaItens, dadosEditando) {
        let indice = listaItens.findIndex((item) => (item === dadosEditando));
        listaItens.splice(indice, 1, { ...dados });
        dadosTabela.setDadosItens(listaItens);
        notification.success({ message: 'Aviso', description: 'Item editado com sucesso!' });
    }

    function popularItemNota(editando, item, dadosEditando) {
        let itemNota = {};
        let formulario = form.getFieldValue();
        let valorIpi = 0;
        let valorSt = 0;

        if (editando) {
            if (isObjetoDiffVazio(dadosEditando.impostos)) {
                valorSt = dadosEditando.impostos.retornoICMSST.ret_valorIcmsSt;
                valorIpi = dadosEditando.impostos.retornoIPI.ret_valorIpi;
            }
            itemNota = {
                ...dadosEditando,
                impostos: dadosEditando.impostos,
                iod_uforigem: dadosEditando.iod_uforigem,
                iod_ufdestino: dadosEditando.iod_ufdestino,
                orm_id: dadosEditando.orm_id,
                ltp_id: dadosEditando.ltp_id,
                nfi_qtde: formulario.quantidade,
                nfi_numeropedidocompra: formulario.nfi_numeropedidocompra,
                nfi_itempedidocompra: formulario.nfi_itempedidocompra,
                nfi_valorunitario: parseFloat(formulario.valorUnitario),
                percdesc: parseFloat(formulario.percdesc),
                nfi_valorfrete: dadosEditando.nfi_valorfrete,
                nfi_valoroutros: parseFloat(formulario.acrescimo),
                nfi_valordesconto: parseFloat(formulario.desconto),
                percacresc: parseFloat(formulario.percacresc),
                nfi_valoricmsst: parseFloat(valorSt),
                nfi_valoripi: parseFloat(valorIpi),
                nfi_informacaoadicional: formulario.informacaoAdicional,
                nfi_cfop: formulario.nfi_cfop,
                total: parseFloat(parseFloat(formulario.valorItemDesconto) + parseFloat(valorSt)),
                icm_id: formulario.icm_id,
                cso_id: retImposto.cso_id ? retImposto.cso_id : item.cso_id ?? dadosProdutoImpostos.cso_id,
                cst_id: retImposto.cst_id ? retImposto.cst_id : item.cst_id ?? dadosProdutoImpostos.cst_id
            };
        } else {
            let cfop = dadosCfop.filter((cfop) => (cfop.cfo_cfop === formulario.nfi_cfop))[0];
            if (isObjetoDiffVazio(item.impostos)) {
                valorSt = item.impostos.retornoICMSST.ret_valorIcmsSt;
                valorIpi = item.impostos.retornoIPI.ret_valorIpi;
            }
            itemNota = {
                impostos: item.impostos,
                iod_uforigem: item.iod_uforigem,
                iod_ufdestino: item.iod_ufdestino,
                orm_id: item.orm_id,
                ltp_id: item.ltp_id,
                pro_id: item.pro_id,
                prg_id: item.prg_id,
                nfi_cfop: cfop.cfo_cfop,
                nfi_qtde: formulario.quantidade,
                nfi_numeroitem: !!dadosTabela.dadosItens && dadosTabela.dadosItens.length > 0 ? dadosTabela.dadosItens.length + 1 : 1,
                nfi_valorunitario: parseFloat(formulario.valorUnitario ?? 0),
                total: parseFloat(parseFloat(formulario.valorItemDesconto) + parseFloat(valorSt)),
                percdesc: parseFloat(formulario.percdesc ?? 0),
                nfi_valordesconto: parseFloat(formulario.desconto ?? 0),
                percacresc: parseFloat(formulario.percacresc ?? 0),
                nfi_valoroutros: parseFloat(formulario.acrescimo ?? 0),
                nfi_valoricmsst: parseFloat(valorSt),
                nfi_valoripi: parseFloat(valorIpi),
                nfi_valorfrete: 0,
                nfi_informacaoadicional: formulario.informacaoAdicional,
                cfo_cfop: cfop.cfo_cfop,
                cfo_descricao: cfop.cfo_descricao,
                cfo_id: cfop.cfo_id,
                nfi_ean: HasValue(item.prg_ean) ? item.prg_ean : item.pro_ean,
                nfi_unidademedida: item.ump_id,
                pro_codigo: item.pro_codigo,
                pro_descricao: item.pro_descricao,
                pro_ean: item.pro_ean,
                ump_id: item.ump_id,
                nfi_numeropedidocompra: item.nfi_numeropedidocompra,
                nfi_itempedidocompra: item.nfi_itempedidocompra,
                grade: item.descricaograde,
                nfi_ncm: item.ncm_codigo ?? dadosProdutoImpostos.ncm_codigo,
                cbf_id: formulario.cbf_id,
                icm_id: formulario.icm_id ?? item.icm_id ?? dadosProdutoImpostos.icm_id,
                ncm_id: item.ncm_id ?? dadosProdutoImpostos.ncm_id,
                pis_id: item.pis_id ?? dadosProdutoImpostos.pis_id,
                cso_id: retImposto.cso_id ? retImposto.cso_id : item.cso_id ?? dadosProdutoImpostos.cso_id,
                cst_id: retImposto.cst_id ? retImposto.cst_id : item.cst_id ?? dadosProdutoImpostos.cst_id,
            };
        }
        return itemNota;
    };

    async function incluirItemPedidoCondicional() {
        let listaItens = [];
        let indice = 0;
        carregarPagina(true);
        for (let [idx, itens] of dadosTabela.dadosItens.entries()) {
            let prodNota = await buscarDadosProdImpostos(itens.pro_id)
            let obj = { ...prodNota, ...itens };
            obj.nfi_cfop = obj.cfo_cfop;
            obj.grade = obj.descricaograde;
            obj.nfi_ncm = obj.ncm_codigo.toString();
            listaItens.push(obj)
            if (indice === dadosTabela.dadosItens.length - 1) {
                dadosTabela.setDadosItens(listaItens);
                carregarPagina(false);
            }
            indice = indice + 1;;
        };
    };

    async function buscaPrecoListaPrecoSelecionada() {
        let dadosPreco = await api.get(`ListaPrecoItem/BuscaPrecoItem?CodigoListaPreco=${form.getFieldValue().ltp_id}&CodigoProduto=${dadosDetalhes.pro_id}&CodigoGrade=${!!dadosDetalhes.prg_id ? dadosDetalhes.prg_id : ""}`);
        if (dadosPreco.data) {
            form.setFieldsValue({
                valorUnitario: dadosPreco.data.lpi_valorvenda,
                valorTotalItem: form.getFieldValue().quantidade * dadosPreco.data.lpi_valorvenda,
            });
            onChangeValorUnitario();
        } else (console.log("Item não encontrado na lista de preço selecionada"))
    }

    function validarDescontoMaximoVendedor(perDesconto) {
        let retorno = true;
        if (!!dadosVendedor && !!dadosVendedor.ven_maximodesconto) {
            if (perDesconto > (dadosVendedor.ven_maximodesconto * 100)) {
                notification.warning({ description: "O desconto máximo para este vendedor não pode exceder " + dadosVendedor.ven_maximodesconto * 100 + "% !", message: "Aviso" });
                form.setFieldsValue({
                    desconto: null,
                    percdesc: null
                });
                retorno = false;
            }
        }
        return retorno;
    };

    function limparCampos() {
        form.setFieldsValue({ pro_id: null, pro_codigo: null, percdesc: 0, desconto: 0, percacresc: 0, acrescimo: 0, nfi_cfop: null, icm_id: null, informacaoAdicional: '' });
    };

    function editandoItem(dadosEditando) {
        let dadosTemp = {};
        let dados = dadosEditando;
        form.setFieldsValue({
            pro_id: dados.pro_id,
            prg_id: dados.prg_id,
            quantidade: dados.nfi_qtde,
            valorUnitario: dados.nfi_valorunitario,
            nfi_numeroitem: dados.nfi_numeroitem,
            valorTotalItem: dados.nfi_qtde * dados.nfi_valorunitario,
            valorItemDesconto: (dados.nfi_qtde * dados.nfi_valorunitario) - dados.nfi_valordesconto + dados.nfi_valoroutros,
            desconto: dados.nfi_valordesconto,
            nfi_valorfrete: dados.nfi_valorfrete,
            percacresc: dados.percacresc,
            percdesc: dados.percdesc,
            nfi_cfop: dados.nfi_cfop,
            acrescimo: dados.nfi_valoroutros,
            informacaoAdicional: dados.nfi_informacaoadicional,
            icm_id: (!!dados.icm_id ? dados.icm_id : null), ncm_id: (!!dados.ncm_id ? dados.ncm_id : null), pis_id: (!!dados.pis_id ? dados.pis_id : null),
            nfi_itempedidocompra: dados.nfi_itempedidocompra,
            nfi_numeropedidocompra: dados.nfi_numeropedidocompra
        });
        dadosTemp = {
            prg_id: dadosEditando.prg_id,
            pro_descricao: dadosEditando.pro_descricao,
            pro_codigo: dadosEditando.pro_codigo,
            pro_ean: dadosEditando.pro_ean,
            ncm_codigo: dadosEditando.ncm_codigo,
            ncm_id: dadosEditando.ncm_id,
            ncm_porcentagem: dadosEditando.ncm_porcentagem,
            ump_id: dadosEditando.ump_id,
            informacaoAdicional: dadosEditando.nfi_informacaoadicional,
        };
        setDadosDetalhes(dadosTemp);
    }
    //#endregion

    return (
        <div>
            <Row gutter={[8, 0]}>
                {!!!buscarProdutosCodBarra &&
                    <Col xs={21} sm={21} md={21} lg={22} xl={22}>
                        <Form.Item label="Selecione o Produto" name="pro_id">
                            <SelectProduto disabled={desabilitarProd_id} valueKey={true} detalhes={true}
                                placeholder="Selecione o Produto"
                                name="pro_id"
                                form={form}
                                ordem={"pro_descricao"}
                                onChange={(dados) => { onChangeProID(dados) }}
                                setListaDados={setListaSelectProd} />
                        </Form.Item>
                    </Col>
                }
                {!!buscarProdutosCodBarra &&
                    <Col xs={21} sm={21} md={21} lg={22} xl={22}>
                        <Form.Item label="Localize o Produto" name="pro_codigo">
                            <Input ref={codBarras} onPressEnter={(value) => {
                                onPressEnterProCodigo(value)
                            }} placeholder="Informe o Produto/Mercadoria" />
                        </Form.Item>
                    </Col>
                }
                <Col xs={3} sm={3} md={3} lg={2} xl={2} className="i-button">
                    <Tooltip title={!!buscarProdutosCodBarra ? 'Localize produto por EAN' : 'Selecione o produto'} placement="left">
                        <Button onClick={togglePesquisa} block>
                            {buscarProdutosCodBarra ? <SearchOutlined /> : <BarcodeOutlined />}
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
            {exibirDetalhes &&
                <div className="m-t-16">
                    <Card title={
                        <Row gutter={[8, 0]}>
                            <Col>
                                Produto: <b>{dadosDetalhes.pro_descricao} {!!dadosDetalhes.descricaograde ? dadosDetalhes.descricaograde : ''}</b>
                            </Col>
                            <Col>
                                Código Interno: <b>{dadosDetalhes.pro_codigo}</b>
                            </Col>
                            <Col>
                                Código de Barras: <b>{!!dadosDetalhes.prg_ean ? dadosDetalhes.prg_ean : dadosDetalhes.pro_ean}</b>
                            </Col>
                            <Col>
                                NCM: <b>{dadosDetalhes.ncm_codigo}</b>
                            </Col>
                        </Row>
                    }>
                        <Row gutter={[8, 0]}>
                            <Form.Item hidden label="Id da Grade do Produto" name="prg_id">
                                <Input></Input>
                            </Form.Item>
                            <Form.Item hidden label="Valor Frete" name="nfi_valorfrete">
                                <Input></Input>
                            </Form.Item>
                            {!ocultaListaPreco && <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="Lista Preço" name="ltp_id">
                                    <SelectPaginacao url="ListaPreco/ListarListaPreco" placeholder="Selecione a Lista" form={form} nameLabel="ltp_nome" nameValue="ltp_id" onChangeFunction={(valor) => buscaPrecoListaPrecoSelecionada(valor)} conteudo={
                                        lp => (<Select.Option value={lp.ltp_id} key={lp.key}>{lp.ltp_id} - {lp.ltp_nome}</Select.Option>)
                                    } />
                                </Form.Item>
                            </Col>}
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="CFOP - Código Fiscal de Operação" name="nfi_cfop" rules={[{ required: true, message: 'Informe o CFOP' }]}>
                                    <Select showSearch optionFilterProp="children" placeholder="Selecione um CFOP">
                                        {dadosCfop.filter(cfop => { return form.getFieldValue().interestadual ? cfop.cfo_cfop.substring(0, 1) === "6" : cfop.cfo_cfop.substring(0, 1) !== "6" }).map((cfop) => (
                                            <Select.Option value={cfop.cfo_cfop} key={cfop.cfo_id}>{cfop.cfo_cfop} - {cfop.cfo_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="ICMS" name="icm_id">
                                    <SelectPaginacao url="TributacaoIcms/Listar" placeholder="Selecione o ICMS" form={form} nameLabel="icm_descricao" nameValue="icm_id" onChangeFunction={(value) => buscarDadosIcms(value)} conteudo={
                                        icm => (<Select.Option value={icm.icm_id} key={icm.key}>{icm.icm_id} - {icm.icm_descricao}</Select.Option>)
                                    } />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="NCM" name="ncm_id">
                                    <SelectPaginacao url="Ncm/Listar" placeholder="Selecione o NCM" form={form} nameLabel="ncm_descricao" nameValue="ncm_id" onChangeFunction={(valor) => carregarNcm(valor)} conteudo={
                                        ncm => (<Select.Option value={ncm.ncm_id} key={ncm.key}>{ncm.ncm_codigo.codigoNcm} - {ncm.ncm_descricao}</Select.Option>)
                                    } />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={4} md={3} lg={3} xl={3}>
                                <Form.Item label="Quantidade" name="quantidade">
                                    <InputNumber decimalSeparator="," min={0} step={1} placeholder="Informe a Quantidade do Produto" onChange={() => { onChangeQuantidade() }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={6} md={4} lg={4} xl={4}>
                                <InputPreco label="Valor Unitário (R$)" name="valorUnitario" precision={2} onChange={() => onChangeValorUnitario()} />
                            </Col>
                            <Col xs={24} sm={6} md={4} lg={4} xl={4}>
                                <InputPreco label="Valor Total do Item (R$)" name="valorTotalItem" disabled={true} />
                            </Col>
                            <Col xs={24} sm={4} md={3} lg={2} xl={2}>
                                <Form.Item label="Desc. (%)" name="percdesc">
                                    <InputNumber
                                        onChange={() => (onChangePercDesc())}
                                        precision={2}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={4} md={3} lg={2} xl={2}>
                                <InputPreco label="Desc. (R$)" name="desconto" precision={2} onChange={() => onChangeDesconto()} />
                            </Col>
                            <Col xs={24} sm={4} md={3} lg={2} xl={2}>
                                <Form.Item label="Acrésc. (%)" name="percacresc">
                                    <InputNumber
                                        onChange={() => { onChangePercAcresc() }}
                                        precision={2}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={4} md={3} lg={2} xl={2}>
                                <InputPreco label="Acrésc. (R$)" name="acrescimo" onChange={() => { onChangeAcrescimo() }} />
                            </Col>
                            <Col xs={24} sm={16} md={6} lg={5} xl={5}>
                                <InputPreco label="VALOR TOTAL (R$)" name="valorItemDesconto" disabled={true} />
                            </Col>
                            {/* <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item label="PIS / COFINS" name="pis_id">
                                    <SelectPaginacao url="TributacaoPisCofins/Listar" placeholder="Selecione o PIS/COFINS" form={form} nameLabel="pis_descricao" nameValue="pis_id" conteudo={
                                        pis => (<Select.Option value={pis.pis_id} key={pis.key}>{pis.pis_id} - {pis.pis_descricao}</Select.Option>)
                                    } />
                                </Form.Item>
                            </Col> */}
                            {dadosContribuinte.emp_regimetributario === 3 && <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item label="Código Benefício Fiscal" name="cbf_id">
                                    <SelectPaginacao url="TabelaCbenef/Listar" placeholder="Informe o Código Benefício Fiscal" form={form} allowClear nameValue="cbf_id" nameLabel={"cbf_codigo"}
                                        conteudo={
                                            v => (<Select.Option value={v.cbf_id} key={v.key}>{v.cbf_codigo} - {v.cbf_descricao} </Select.Option>)
                                        } />
                                </Form.Item>
                            </Col>}
                            {nfSaida === true && (
                                <>
                                    <Col xs={24} sm={4} md={4} lg={4} xl={3}>
                                        <Form.Item label="Nº Pedido de Compra" name="nfi_numeropedidocompra">
                                            <Input placeholder="Nº do pedido de compra" maxLength={15} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={3} md={3} lg={3} xl={3}>
                                        <Form.Item label="Nº Item Pedido de Compra" name="nfi_itempedidocompra">
                                            <InputNumber placeholder="Nº Item do pedido de compra" max={6} />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                            <Col xs={24} sm={17} md={17} lg={17} xl={nfSaida ? 18 : 24}>
                                <Form.Item label="Informações Adicionais do Produto" name="informacaoAdicional">
                                    <Input.TextArea placeholder="Informe as Observações Referente o Produto/Mercadoria" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="middle" justify="end" gutter={[8, 0]} className="m-t-8">
                            <Col>
                                <Button icon={<CloseOutlined />} onClick={() => (onClickBtnCancelar())}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button icon={<PlusOutlined />} type="primary" onClick={() => onClickBtnAdicionar()}>
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </div>}
            <ModalManutencaoNota formulario={form} exibirModal={abrirModalManutencaoNota} fecharModal={() => { setAbrirModalManutencaoNota(false); }} dadosContribuinte={dadosContribuinte} dadosOperacao={dadosOperacao} dadosEndereco={dadosEndereco} dadosProduto={dadosProdutoImpostos} aoSalvar={(value) => buscarDadosProdImpostos(value)} />
            <ModalSenhaLiberacao exibirModal={abreModalLiberacao} fecharModal={onCloseModalLiberacao} />
            <ModalSelectLote exibirModal={abrirModalLote} fecharModal={(itemCLote, listaTemp) => salvarLotes(itemCLote, listaTemp)} dadosLote={dadosLote} setDadosLote={setDadosLote} formulario={form} dadosItem={dadosItem} />
        </div >
    );
}