import React, { useEffect, useState } from "react";
import { Row, Col, Upload, Button, notification, Form } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

import { BreadcrumbPage } from "../../components";
import { getBase64 } from "../../services/funcoes";
import api from "../../services/api";
import { buscarDadosTabela, criarBanco, excluirBancoDados, incluirBanco } from "../../services/webSql";
import { manutencaoActions } from "../../actions";
import { useStateValue } from "../../state";
import ImportacaoNotaFiscalEntrada from "../importacaoNotaFiscalEntrada";

export default function ImportarNotaFiscalEntrada() {
    const [arquivoBase64, setArquivoBase64] = useState("");
    const [arquivo, setArquivo] = useState(null);
    const [formNotaEntrada] = Form.useForm();
    const [openNota, setOpenNota] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [versionDB, setVersionDB] = useState(1);
    const [{ manutencao }, dispatch] = useStateValue();
    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        maxCount: 1,
        disabled: !!arquivo,
        beforeUpload(file) {
            getBase64(file).then(base64 => {
                setArquivoBase64(base64.split('base64,')[1]);
                setArquivo(file);
            });
        }
    };

    useEffect(() => {
        let chave = localStorage.getItem('@GestorWeb-ChaveImportar');
        if (!!chave) {
            carregarNota(chave);
        }
    }, []);

    function carregarNota(chave) {
        setCarregando(true);
        api.post(`NotaFiscalEntrada/CarregarNfe?ChaveNfe=${chave}`).then(
            res => {
                let dados = { ...res.data };
                let dadosNota = [];
                incluirDadosNota(dados, dadosNota);
                localStorage.removeItem('@GestorWeb-ChaveImportar');
            }
        ).catch(
            erro => {
                notification.error({ description: erro.response.data, message: 'Aviso' });
                setCarregando(false);
                console.log(erro);
            }
        )
    };

    function validarNotaFiscal() {
        /* ValidarNfe*/
        // setCarregando(true);
        // api.post(`NotaFiscalEntrada/ValidarXML`, JSON.stringify(arquivoBase64), { headers: { 'Content-Type': 'application/json;charset=utf-8' } }).then(
        //     res => {
        //         let dados = { ...res.data };
        //         let dadosNota = [];
        //         dadosNota.push({ nome: 'xmlBase64', valor: arquivoBase64 });
        //         incluirDadosNota(dados, dadosNota);
        //     }
        // ).catch(
        //     erro => {
        //         setCarregando(false);
        //         notification.error({ description: erro.response.data, message: 'Aviso' });
        //         removerNota();
        //     }
        // );

        /*Importar XML da empresa*/
        setCarregando(true);
        let obj = {
            chaveNfe: arquivo.name,
            xmlDistribuicaoNfe: arquivoBase64
        }
        api.post(`NotaFiscal/ImportarXmlNota`, obj, { headers: { 'Content-Type': 'application/json;charset=utf-8' } }).then(
            res => {
                notification.error({ description: "Nota Fiscal importada", message: 'Aviso' });
            }
        ).catch(
            erro => {
                setCarregando(false);
                notification.error({ description: erro.response.data, message: 'Aviso' });
            }
        );
    };

    async function incluirDadosNota(dados, dadosNota) {
        let dadosItens = [];
        let dadosCamposItens = [];
        let dadosFaturamento = [];
        let dadosFat = [];
        let dadosTransp = [];
        let dadosTransportador = [];
        let dadosRem = [];
        let dadosRemetente = [];
        for (let key in dados) {
            if (typeof dados[key] !== 'object') {
                dadosNota.push({ nome: key, valor: dados[key] });
            } else if (key === 'dadosItens') {
                dados.dadosItens.forEach((item) => {
                    for (let keyItens in item) {
                        if (!!!item[keyItens]) {
                            dadosCamposItens.push({ nome: keyItens, unico: false, valor: null });
                        } else if (typeof item[keyItens] === 'object') {
                            dadosCamposItens.push({ nome: keyItens, unico: false, valor: JSON.stringify(item[keyItens]) });
                        } else {
                            dadosCamposItens.push({ nome: keyItens, unico: false, valor: item[keyItens] });
                        }
                    }
                    dadosItens.push(dadosCamposItens);
                    dadosCamposItens = [];
                });
            } else if (key === 'dadosFaturamento') {
                dados.dadosFaturamento.forEach((fat) => {
                    for (let keyFat in fat) {
                        if (!!!fat[keyFat]) {
                            if (keyFat === "nfp_valor") {
                                dadosFat.push({ nome: keyFat, unico: false, valor: 0 });
                            } else {
                                dadosFat.push({ nome: keyFat, unico: false, valor: null });
                            }
                        } else if (typeof fat[keyFat] === 'object') {
                            dadosFat.push({ nome: keyFat, unico: false, valor: JSON.stringify(fat[keyFat]) });
                        } else {
                            dadosFat.push({ nome: keyFat, unico: false, valor: fat[keyFat] });
                        }
                    }
                    dadosFaturamento.push(dadosFat);
                    dadosFat = [];
                });
            } else if (key === 'dadosRemetente') {
                for (let keyRem in dados.dadosRemetente) {
                    if (!!!dados.dadosRemetente[keyRem]) {
                        dadosRem.push({ nome: keyRem, unico: false, valor: null });
                    } else if (typeof dados.dadosRemetente[keyRem] === 'object') {
                        dadosRem.push({ nome: keyRem, unico: false, valor: JSON.stringify(dados.dadosRemetente[keyRem]) });
                    } else {
                        dadosRem.push({ nome: keyRem, unico: false, valor: dados.dadosRemetente[keyRem] });
                    }
                }
                dadosRemetente.push(dadosRem);
                dadosRem = [];
            } else if (key === 'dadosTransportador') {
                for (let keyTransp in dados.dadosTransportador) {
                    if (!!!dados.dadosTransportador[keyTransp]) {
                        dadosTransp.push({ nome: keyTransp, unico: false, valor: null });
                    } else if (typeof dados.dadosTransportador[keyTransp] === 'object') {
                        dadosTransp.push({ nome: keyTransp, unico: false, valor: JSON.stringify(dados.dadosTransportador[keyTransp]) });
                    } else {
                        dadosTransp.push({ nome: keyTransp, unico: false, valor: dados.dadosTransportador[keyTransp] });
                    }
                }
                dadosTransportador.push(dadosTransp);
                dadosTransp = [];
            }
        }
        let retorno = [];
        excluirBancoDados(() => {
            criarBancoWeb('notaEntrada', 1, dadosNota, () => {
                criarBancoWeb('dadosItens', 2, dadosItens, () => {
                    criarBancoWeb('dadosFaturamento', 3, dadosFaturamento, () => {
                        criarBancoWeb('dadosRemetente', 4, dadosRemetente, () => {
                            criarBancoWeb('dadosTransportador', 5, dadosTransportador, () => {
                                buscarDadosTabela(retorno, 'notaEntrada', () => {
                                    buscarDadosTabela(retorno, 'dadosItens', () => {
                                        buscarDadosTabela(retorno, 'dadosFaturamento', () => {
                                            buscarDadosTabela(retorno, 'dadosRemetente', () => {
                                                buscarDadosTabela(retorno, 'dadosTransportador', () => {
                                                    setCarregando(false);
                                                    const vetorSeparado = {
                                                        notaEntrada: retorno[0],
                                                        dadosItens: retorno[1],
                                                        dadosFaturamento: retorno[2],
                                                        dadosRemetente: retorno[3],
                                                        dadosTransportador: retorno[4]
                                                    };
                                                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: vetorSeparado } });
                                                    setOpenNota(true);
                                                }, 5);
                                            }, 5);
                                        }, 5);
                                    }, 5);
                                }, 5);
                            });
                        });
                    });
                });
            });
        });
    };


    function removerNota() {
        setArquivoBase64("");
        setArquivo(null);
        excluirBancoDados();
    };

    async function criarBancoWeb(nomeBanco, version, campos, callback) {

        await criarBanco(nomeBanco, campos, version);
        await incluirBanco(nomeBanco, campos, version);
        if (callback) {
            callback();
        }
    }


    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage />
                </Col>
            </Row>
            {!openNota && <div className="upload m-t-16">
                <Upload.Dragger {...props}>
                    {!!!arquivo && <div>
                        <Row align="middle" justify="center" gutter={[0, 8]}>
                            <Col span={24}>
                                <p className="ant-upload-drag-icon m-0">
                                    <CloudUploadOutlined />
                                </p>
                            </Col>
                            <Col span={24}>
                                <p>Arraste o xml aqui! (o arquivo deve estar no formato .xml)</p>
                            </Col>
                            <Col span={24}>
                                <Button loading={carregando} type="primary" size="large" icon={<CloudUploadOutlined />}>
                                    Importar XML
                                </Button>
                            </Col>
                        </Row>
                    </div>}
                    {!!arquivo && <div>
                        <Row align="middle" justify="center" gutter={[8, 8]}>
                            <Col >
                                <svg t="1622205362360" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2101" id="mx_n_1622205362361" data-spm-anchor-id="a313x.7781069.0.i7" width="60" height="60"><path d="M354.40128 0c-87.04 0-157.44 70.55872-157.44 157.59872v275.68128H78.72c-21.6576 0-39.36256 17.69984-39.36256 39.36256v236.31872c0 21.6576 17.69984 39.35744 39.36256 39.35744h118.24128v118.08256c0 87.04 70.4 157.59872 157.44 157.59872h472.63744c87.04 0 157.59872-70.55872 157.59872-157.59872V315.0336c0-41.74848-38.9888-81.93024-107.52-149.27872l-29.11744-29.12256L818.87744 107.52C751.5392 38.9888 711.39328 0 669.59872 0H354.4064z m0 78.72h287.20128c28.35456 7.0912 27.99616 42.1376 27.99616 76.8v120.16128c0 21.6576 17.69984 39.35744 39.36256 39.35744h118.07744c39.38816 0 78.87872-0.0256 78.87872 39.36256v512c0 43.32032-35.55328 78.87872-78.87872 78.87872H354.4064c-43.32544 0-78.72-35.5584-78.72-78.87872v-118.08256h393.91744c21.66272 0 39.36256-17.69472 39.36256-39.35744V472.64256c0-21.66272-17.69984-39.36256-39.36256-39.36256H275.68128V157.59872c0-43.32032 35.39456-78.87872 78.72-78.87872zM145.12128 507.36128h23.99744l39.36256 67.2 40.32-67.2h23.04l-50.88256 83.51744 54.72256 92.16h-24.96l-43.20256-75.83744-43.19744 75.83744h-23.04l54.71744-92.16-50.87744-83.51744z m154.55744 0h32.64l49.92 143.03744h0.96256l48.95744-143.03744h33.60256v175.67744h-22.08256v-106.55744c0-10.88 0.32256-26.56256 0.96256-47.04256h-0.96256l-52.79744 153.6h-19.2l-52.80256-153.6h-0.95744c1.28 22.4 1.92 38.72256 1.92 48.96256v104.63744H299.6736V507.36128z m214.08256 0h22.07744v155.52h69.12v20.15744h-91.19744V507.36128z" p-id="2102"></path></svg>
                            </Col>
                            <Col span={24}>
                                {arquivo.name}
                            </Col>
                            <Col >
                                <Button loading={carregando} type="primary" size="large" icon={<CloudUploadOutlined />} onClick={() => { validarNotaFiscal(); }}>
                                    Validar XML
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" size="large" icon={<CloudUploadOutlined />} onClick={() => { removerNota(); }}>
                                    Remover Nota
                                </Button>
                            </Col>
                        </Row>
                    </div>}
                </Upload.Dragger>
            </div>}
            <ImportacaoNotaFiscalEntrada form={formNotaEntrada} exibir={openNota} fechar={() => { setOpenNota(false) }} />
        </div >
    );

}